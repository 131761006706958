import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import config from '../../data/SiteConfig'
import Categories from './Categories'
import styles from './Header.module.scss'

const Header = () => (
  <header>
    <a href="/"><img src="logos/willogy-logo.png" width="50" height="50" alt="logo" align="left"/></a>
    <h1>
      <Link to="/" activeClassName={styles.activeNav}>
        {config.siteTitle}
      </Link>
    </h1>

    <nav>
      <ul className={styles.mainNav}>
        <li>
          <Link to="/insights" activeClassName={styles.activeNav}>
            Insights
          </Link>
        </li>
        <Categories activeClassName={styles.activeNav} />
        <li>
          <a href="https://willogy.io">
            About us
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

export default Header
