const config = {
  siteTitle: 'Willogy Case Studies', // Site title.
  siteTitleShort: 'Willogy CS', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Willogy Works', // Alternative site title for SEO.
  siteLogo: '/logos/willogy-logo.png', // Logo used for SEO and manifest.
  siteUrl: 'https://works.willogy.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Willogy Case Studies & Insights', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteFBAppID: '', // FB Application ID for using app insights
  googleAnalyticsID: 'UA-46874601-7', // GA tracking ID.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  userName: 'Quang Tran', // Username to display in the author segment.
  userEmail: 'duyquang195@gmail.com', // Email used for RSS feed's author segment
  userTwitter: 'willogy-team', // Optionally renders "Follow Me" in the Bio segment.
  userLinkedin: 'willogy', // Optionally renders "Follow Me" in the Bio segment.
  userFacebook: 'willogyteam', // Optionally renders "Follow Me" in the Bio segment.
  userGitHub: 'willogy-team', // Optionally renders "Follow Me" in the Bio segment.
  userLocation: 'Vietnam', // User location to display in the author segment.
  userAvatar: 'https://avatars2.githubusercontent.com/u/67705429', // User avatar to display in the author segment.
  userDescription:
    "Quang D. Tran", // User description to display in the author segment.
  copyright: 'Willogy © 2021. All rights reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: 'red' // Used for setting manifest background color.
}

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
// if (config.siteRss && config.siteRss[0] !== "/")
//   config.siteRss = `/${config.siteRss}`;

module.exports = config
