import React from 'react'
import styles from './Footer.module.scss'
import config from '../../data/SiteConfig'
import { GoMarkGithub } from "react-icons/go";
import { SiFacebook, SiLinkedin } from "react-icons/si"

const iconSize = 32;

const Footer = () => (
  <footer>
    <div className={styles.container}>
      <div>
        <a
          href={`https://github.com/${config.userGitHub}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GoMarkGithub round size={iconSize} />
        </a>
        <a
          href={`https://www.facebook.com/${config.userFacebook}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiFacebook round size={iconSize} />
        </a>
        <a
          href={`https://www.linkedin.com/company/${config.userLinkedin}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SiLinkedin round size={iconSize} />
        </a>
        {
        // <a
        //   href={`https://twitter.com/${config.userTwitter}`}
        //   target="_blank"
        //   rel="noopener noreferrer"
        // >
        //   Twitter
        // </a>
        }
      </div>
      <div className={styles.copyright}>{config.copyright}</div>
    </div>
  </footer>
)

export default Footer
